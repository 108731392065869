import React, {useState} from 'react';
import {useStaticQuery, graphql} from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image';
import {GalleryModal} from "../modal/GalleryModal.js"
import Masonry from 'react-masonry-component';

const WildlifeGallery = () => {

    const data = useStaticQuery(graphql `
    query wildlifePage {
        sanityWildlifePage {
          wildlifeGallery {
            alt
            asset {
              gatsbyImageData
            }
            _key
          }
        }
      } 
    `);

    const imgData = data.sanityWildlifePage.wildlifeGallery

    const [showModal,
        setShowModal] = useState(false);

    const [selectedItem,
        setSelectedItem] = useState("");

    const openModal = (image) => {
        setSelectedItem(image)
        setShowModal(prev => !prev);
    };

    const wildlifeImages = imgData.map(image => {
        const handleClick = () => {
            openModal(image)
        }
        return (
            <li className="gallery-item" key={image._key} onClick={handleClick}>
                <GatsbyImage
                    image={image.asset
                    ?.gatsbyImageData}
                    alt={image.alt
                    ? image.alt
                    : ""}
                    className="cursor-pointer hover:opacity-90 transition-opacity duration-300"/>
            </li>
        )
    });

    return ( <> <section id="gallery" className="md:ml-25rem h-screen overflow-y-scroll">
        <Masonry
            className={'gallery-list'}
            elementType={'ul'}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}>
            {wildlifeImages}
        </Masonry>
    </section> < GalleryModal modalContent = { <> <GatsbyImage
        image={selectedItem.asset
        ?.gatsbyImageData}
        alt=""
        objectFit="contain"
        className="flex-wrapper"
        imgClassName="mx-auto max-w-full max-h-full h-auto w-auto inline-block relative"/> </>}
                showModal={showModal}
                setShowModal={setShowModal}
                modalCaption={<>{selectedItem.alt
                ? selectedItem.alt
                : ""}</ >
    }/> </>);
};

export default WildlifeGallery;