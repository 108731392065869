import React from 'react';
import SEO from '../components/seo';
import LayoutPortfolio from '../containers/layout-portfolio';
import WildlifeGallery from '../components/gallery/wildlifeGallery';

const WildlifePage = () => {

    return (
        <LayoutPortfolio>
            <SEO title="Wildlife Photos" description="Lifestyle photos by Anatole Tuzlak"/>
            <WildlifeGallery/>
        </LayoutPortfolio>
    );
};

export default WildlifePage;
